import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Input, Modal, TextField } from '@mui/material';
import { formatDateForDisplay } from '../../state/DateUtils';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { useInterval } from '../../utils/use_interval';
import { credentialSelector, getCredential, verifyCredential } from '../../state/slices/credential';

export const LoginWithQRCode: FC = () => {
    const dispatch = useAppDispatch();
    const [pollInternal, setPollInternal] = useState(1000);
    let navigate = useNavigate();
    const credentialState = useSelector(credentialSelector);
    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
       dispatch(verifyCredential({}));
    }, []);

    useInterval(() => {
        retrieveStatus(); // Only poll for changes when the card might be issued
        }, pollInternal
    );


    function retrieveStatus() {
        if (credentialState.singleItem?.sessionId) {
            dispatch(getCredential({sessionId: credentialState.singleItem?.sessionId})).then((response) => {
                if (response.payload) {
                    console.log(response.payload);
                    setPollInternal(0);

                    keycloak.token = response.payload;
                    keycloak.tokenParsed = {};
                    keycloak.authenticated = true;

                    navigate('/');
                }
            });
        }
    }

    function annuleer() {
        navigate('/');
    }

    return (
        <div>
            <Card variant="outlined">
                <CardHeader title='Login met personal wallet'/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Scan onderstaande QR code met je persoonlijke wallet om met behulp van je bevoegdheid uit je wallet in te loggen.
                        </Grid>
                        <Grid item xs={12}>
                            { credentialState.singleItem ? <QRCodeCanvas
                                value={credentialState.singleItem!.oidcUrl}
                                size={300}/> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='outlined' onClick={() => annuleer()}>Annuleren</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};
