import { Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Edit, Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface Props {
}


export const Header: React.FC<Props> = (props) => {
    let {keycloak} = useKeycloak();
    let navigate = useNavigate();

    const [userName, setUserName] = useState<string>(' ');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function logoff() {
        keycloak.logout();
    }

    useEffect(() => {
        keycloak.loadUserInfo().then((userInfo: any) => {
            if (userInfo.name === undefined) {
                setUserName(userInfo.preferred_username);
            } else {
                setUserName(userInfo.name);
            }
        });
    }, [keycloak]);


    function editProfile() {
        navigate('/company/edit');
    }

    return (

        <Box>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between', background: '#DDE6DF', padding: '.5rem 0', paddingLeft: '80px', paddingRight: '80px', height: '98px'}}>
                <img src="/full_logo.png"/>
                <Box sx={{display: 'flex'}}>
                    <a href="/" style={{textDecoration: 'none', color: 'unset'}}><Typography sx={{paddingLeft: '16px', fontFamily: 'sans-serif'}}>HOME</Typography></a>
                    <Typography sx={{paddingLeft: '16px', fontFamily: 'sans-serif'}}>ABOUT US</Typography>
                    <Typography sx={{paddingLeft: '16px', fontFamily: 'sans-serif'}}>SERVICES</Typography>
                    <Typography sx={{paddingLeft: '16px', fontFamily: 'sans-serif'}}>CONTACT US</Typography>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                    <Typography sx={{paddingLeft: '8px', fontFamily: 'sans-serif'}}>{userName}</Typography>
                    <React.Fragment>
                        <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ml: 2}}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{width: 32, height: 32}}>{userName?.substring(0, 1)?.toUpperCase()}</Avatar>
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={editProfile}>
                                <ListItemIcon>
                                    <Edit fontSize="small"/>
                                </ListItemIcon>
                                Profiel bewerken
                            </MenuItem>
                            <MenuItem onClick={logoff}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Uitloggen
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                </Box>
            </Box>
        </Box>
    );
};

